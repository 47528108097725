.list-item{

    padding-top: 16px;
    padding-bottom: 10px;

    h1{
        font-size: 1.8rem;
    }


    background: rgb(186,234,0);
    background: linear-gradient(180deg, rgb(162, 200, 1) 7%, rgba(14,113,0,1) 100%);

    &.selected {
        background: white;
    }
}


    .getMoreBtn {
        background-color: #BAEA00 !important;
        border-radius:3px;
        display:inline-block;
        cursor:pointer;
        color: #164E08 !important;
        font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        padding:13px 28px;
        text-decoration:none;
    }
    .getMoreBtn:hover {
        background-color: #acd409 !important;
    }
    .getMoreBtn:active {
        position:relative;
        top:1px;
    }

