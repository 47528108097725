.square {
  position: relative;
  width: calc(50% - 1rem);
  margin: 0.5rem;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &--mini {
    width: calc(50% - .2rem);
    margin: .1rem;
  }
}
