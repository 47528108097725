@import 'node_modules/bootstrap/scss/functions';
@import './_variables.scss';
@import './components.scss';
@import './square.scss';

html,
body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {

  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  overflow-x: hidden;
  height: 100%;
  background: rgb(186, 234, 0);
  background: radial-gradient(circle, rgba(186, 234, 0, 1) 4%, rgba(14, 113, 0, 1) 81%, rgba(0, 0, 0, 1) 100%);
  //background-attachment: fixed;

  &--linear {
    background: rgb(14, 113, 0);
    background: linear-gradient(0deg, rgba(14, 113, 0, 1) 0%, rgba(186, 234, 0, 1) 100%);
  };
}


#root {
  height: 100%;
}



// utilities
.pointer {
  cursor: pointer;
  box-shadow: none;
}

.btn:focus {
  outline: none;
}

.btn-primary:focus,
.btn-info:focus {
  box-shadow: none;
}

.hide {
  opacity: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.p-small{
  font-size: .8rem;
}

.p-big{
  font-size: 1.3rem;
}



.h-12 {
  height: 3rem;
}
