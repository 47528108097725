// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #7C0202 !default;
$orange:  #fd7e14 !default;
$yellow:  #FFFF74 !default;
$green:   #6EB400 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$lime: #BAEA00;
$darkGreen: #164E08;
$orange: #E29C00;
$brightRed: #FE0100;
$purple: #7B2D7E;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "pink":       $pink,
                        "red":        $red,
                        "yellow":     $yellow,
                        "green":      $green,
                        "darkGreen":  $darkGreen,
                        "orange":     $orange,
                        "brightRed":  $brightRed,
                        "purple":     $purple,
                        "lime":         $lime,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;


$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
                (
                        "primary":    $primary,
                        "secondary":  $secondary,
                        "success":    $success,
                        "info":       $info,
                        "warning":    $warning,
                        "danger":     $danger,
                        "light":      $light,
                        "dark":       $dark,
                        "yellow":     $yellow,
                        "red":     $red,
                        "green":      $green,
                        "lime":       $lime,
                        "darkGreen":  $darkGreen,
                        "orange":     $orange,
                        "brightRed":  $brightRed,
                        "purple":     $purple,

                ),
                $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;
